<template>
  <b-row>
    <b-col cols="12">
      <table-basic></table-basic>
    </b-col>
  </b-row>
</template>

<script>
import TableBasic from "../../../table/bs-table/TableBasic";
export default {
  name: "ProductPreview.vue",
  components: {TableBasic}
}
</script>

<style scoped>

</style>